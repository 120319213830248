import React from "react";
import "./sapherosection.css";
import "animate.css";
const BOOK_NOW_LINK = process.env.REACT_APP_BOOK_NOW_LINK;

export const SapHeroSection = ({
  capText,
  titleText,
  detailsText,
  gradientColor,
  image,
}) => {
  return (
    <>
      <div
        className="outer-conatiner"
        style={{ background: `${gradientColor}` }}
      >
        <section className="sap-hero container">
          <div className="left">
            <div className="left-cap">
              <span>{capText}</span>
            </div>
            <div className="hero-title">
              <span>{titleText}</span>
            </div>
            <div className="hero-detail">
              <p>{detailsText}</p>
            </div>
            <div className="hero-btn">
              <a href={BOOK_NOW_LINK} target="_blank" rel="noopener noreferrer">
                <button>Book a Demo</button>
              </a>
            </div>
          </div>
          <div className="right ">
            <div className="hero-image">
              <img
                src={image}
                alt="hero-section-img"
                height={"100%"}
                loading="lazy"
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
