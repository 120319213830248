import { useEffect, useState } from "react";
import hero from "../../assets/svg/Home/Looper BG.svg";
import smile_icon_121 from "../../assets/svg/image 121.svg";
import "./home.css";
import { useLocation } from "react-router-dom";

const BOOK_NOW_LINK = process.env.REACT_APP_BOOK_NOW_LINK;

export default function Hero() {
  const location = useLocation();
  const [city, setCity] = useState("");

  useEffect(() => {
    const pathParts = location.pathname.split("-");
    const extractedCity = pathParts[pathParts.length - 1]; // Get "surat"
    setCity(extractedCity);
  }, [location]);

  return (
    <section className={`hero-gradient hero-section-responsive`}>
      <div className="container border border-2">
        <div className="row justify-content-center">
          <div className="hero-label w-auto px-5">
            <p className="text-center fw-semibold">
              Ready to Grow Your Business?
            </p>
          </div>
          <div className="col-lg-10 col-sm-10">
            <div className="text-center">
              <div className="hero-font fw-semibold">
                <span
                  className="m-0 p-0 type"
                // id="type-effect"
                >
                  Explore the new possibilities with SAP!
                  <img
                    alt="smily"
                    src={smile_icon_121}
                    height={64}
                    loading="lazy"
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-sm-10">
            {city === "/" ? (
              <p className="text-center text-muted lh-base">
                We drive innovation as a trusted SAP partner, delivering
                cutting-edge IT solutions to transform your business and empower
                your success in the digital world.
              </p>
            ) : (
              <p className="text-center text-muted lh-base city-text">
                We drive innovation as a trusted{" "}
                <strong>SAP partner from {city}</strong>, delivering
                cutting-edge IT solutions to transform your business and empower
                your success in the digital world.
              </p>
            )}
          </div>
          <div className="bookademo-btn">
            <a
              href={BOOK_NOW_LINK}
              target="_blank"
              className="btn"
              rel="noreferrer"
              style={{ zIndex: "99" }}
            >
              Book a Demo
            </a>
          </div>
        </div>
      </div>
      <div className="hero-svg">
        <img
          alt="smily"
          src={hero}
          height={"100%"}
          width={"100%"}
          loading="lazy"
        />
      </div>
    </section>
  );
}
